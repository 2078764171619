import _flickity from "flickity";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Flickity fade v1.0.0
 * Fade between Flickity slides
 */

/* jshint browser: true, undef: true, unused: true */
(function (window, factory) {
  // universal module definition

  /*globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(_flickity, _fizzyUiUtils);
  } else {
    // browser global
    factory(window.Flickity, window.fizzyUIUtils);
  }
})(exports, function factory(Flickity, utils) {
  // ---- Slide ---- //
  var Slide = Flickity.Slide;
  var slideUpdateTarget = Slide.prototype.updateTarget;

  Slide.prototype.updateTarget = function () {
    slideUpdateTarget.apply(this || _global, arguments);

    if (!(this || _global).parent.options.fade) {
      return;
    } // position cells at selected target


    var slideTargetX = (this || _global).target - (this || _global).x;
    var firstCellX = (this || _global).cells[0].x;

    (this || _global).cells.forEach(function (cell) {
      var targetX = cell.x - firstCellX - slideTargetX;
      cell.renderPosition(targetX);
    });
  };

  Slide.prototype.setOpacity = function (alpha) {
    (this || _global).cells.forEach(function (cell) {
      cell.element.style.opacity = alpha;
    });
  }; // ---- Flickity ---- //


  var proto = Flickity.prototype;
  Flickity.createMethods.push("_createFade");

  proto._createFade = function () {
    (this || _global).fadeIndex = (this || _global).selectedIndex;
    (this || _global).prevSelectedIndex = (this || _global).selectedIndex;
    this.on("select", (this || _global).onSelectFade);
    this.on("dragEnd", (this || _global).onDragEndFade);
    this.on("settle", (this || _global).onSettleFade);
    this.on("activate", (this || _global).onActivateFade);
    this.on("deactivate", (this || _global).onDeactivateFade);
  };

  var updateSlides = proto.updateSlides;

  proto.updateSlides = function () {
    updateSlides.apply(this || _global, arguments);

    if (!(this || _global).options.fade) {
      return;
    } // set initial opacity


    (this || _global).slides.forEach(function (slide, i) {
      var alpha = i == (this || _global).selectedIndex ? 1 : 0;
      slide.setOpacity(alpha);
    }, this || _global);
  };
  /* ---- events ---- */


  proto.onSelectFade = function () {
    // in case of resize, keep fadeIndex within current count
    (this || _global).fadeIndex = Math.min((this || _global).prevSelectedIndex, (this || _global).slides.length - 1);
    (this || _global).prevSelectedIndex = (this || _global).selectedIndex;
  };

  proto.onSettleFade = function () {
    delete (this || _global).didDragEnd;

    if (!(this || _global).options.fade) {
      return;
    } // set full and 0 opacity on selected & faded slides


    (this || _global).selectedSlide.setOpacity(1);

    var fadedSlide = (this || _global).slides[(this || _global).fadeIndex];

    if (fadedSlide && (this || _global).fadeIndex != (this || _global).selectedIndex) {
      (this || _global).slides[(this || _global).fadeIndex].setOpacity(0);
    }
  };

  proto.onDragEndFade = function () {
    // set flag
    (this || _global).didDragEnd = true;
  };

  proto.onActivateFade = function () {
    if ((this || _global).options.fade) {
      (this || _global).element.classList.add("is-fade");
    }
  };

  proto.onDeactivateFade = function () {
    if (!(this || _global).options.fade) {
      return;
    }

    (this || _global).element.classList.remove("is-fade"); // reset opacity


    (this || _global).slides.forEach(function (slide) {
      slide.setOpacity("");
    });
  };
  /* ---- position & fading ---- */


  var positionSlider = proto.positionSlider;

  proto.positionSlider = function () {
    if (!(this || _global).options.fade) {
      positionSlider.apply(this || _global, arguments);
      return;
    }

    this.fadeSlides();
    this.dispatchScrollEvent();
  };

  var positionSliderAtSelected = proto.positionSliderAtSelected;

  proto.positionSliderAtSelected = function () {
    if ((this || _global).options.fade) {
      // position fade slider at origin
      this.setTranslateX(0);
    }

    positionSliderAtSelected.apply(this || _global, arguments);
  };

  proto.fadeSlides = function () {
    if ((this || _global).slides.length < 2) {
      return;
    } // get slides to fade-in & fade-out


    var indexes = this.getFadeIndexes();
    var fadeSlideA = (this || _global).slides[indexes.a];
    var fadeSlideB = (this || _global).slides[indexes.b];
    var distance = this.wrapDifference(fadeSlideA.target, fadeSlideB.target);
    var progress = this.wrapDifference(fadeSlideA.target, -(this || _global).x);
    progress = progress / distance;
    fadeSlideA.setOpacity(1 - progress);
    fadeSlideB.setOpacity(progress); // hide previous slide

    var fadeHideIndex = indexes.a;

    if ((this || _global).isDragging) {
      fadeHideIndex = progress > 0.5 ? indexes.a : indexes.b;
    }

    var isNewHideIndex = (this || _global).fadeHideIndex != undefined && (this || _global).fadeHideIndex != fadeHideIndex && (this || _global).fadeHideIndex != indexes.a && (this || _global).fadeHideIndex != indexes.b;

    if (isNewHideIndex) {
      // new fadeHideSlide set, hide previous
      (this || _global).slides[(this || _global).fadeHideIndex].setOpacity(0);
    }

    (this || _global).fadeHideIndex = fadeHideIndex;
  };

  proto.getFadeIndexes = function () {
    if (!(this || _global).isDragging && !(this || _global).didDragEnd) {
      return {
        a: (this || _global).fadeIndex,
        b: (this || _global).selectedIndex
      };
    }

    if ((this || _global).options.wrapAround) {
      return this.getFadeDragWrapIndexes();
    } else {
      return this.getFadeDragLimitIndexes();
    }
  };

  proto.getFadeDragWrapIndexes = function () {
    var distances = (this || _global).slides.map(function (slide, i) {
      return this.getSlideDistance(-(this || _global).x, i);
    }, this || _global);

    var absDistances = distances.map(function (distance) {
      return Math.abs(distance);
    });
    var minDistance = Math.min.apply(Math, absDistances);
    var closestIndex = absDistances.indexOf(minDistance);
    var distance = distances[closestIndex];
    var len = (this || _global).slides.length;
    var delta = distance >= 0 ? 1 : -1;
    return {
      a: closestIndex,
      b: utils.modulo(closestIndex + delta, len)
    };
  };

  proto.getFadeDragLimitIndexes = function () {
    // calculate closest previous slide
    var dragIndex = 0;

    for (var i = 0; i < (this || _global).slides.length - 1; i++) {
      var slide = (this || _global).slides[i];

      if (-(this || _global).x < slide.target) {
        break;
      }

      dragIndex = i;
    }

    return {
      a: dragIndex,
      b: dragIndex + 1
    };
  };

  proto.wrapDifference = function (a, b) {
    var diff = b - a;

    if (!(this || _global).options.wrapAround) {
      return diff;
    }

    var diffPlus = diff + (this || _global).slideableWidth;
    var diffMinus = diff - (this || _global).slideableWidth;

    if (Math.abs(diffPlus) < Math.abs(diff)) {
      diff = diffPlus;
    }

    if (Math.abs(diffMinus) < Math.abs(diff)) {
      diff = diffMinus;
    }

    return diff;
  }; // ---- wrapAround ---- //


  var _getWrapShiftCells = proto._getWrapShiftCells;

  proto._getWrapShiftCells = function () {
    if (!(this || _global).options.fade) {
      _getWrapShiftCells.apply(this || _global, arguments);
    }
  };

  var shiftWrapCells = proto.shiftWrapCells;

  proto.shiftWrapCells = function () {
    if (!(this || _global).options.fade) {
      shiftWrapCells.apply(this || _global, arguments);
    }
  };

  return Flickity;
});

export default exports;